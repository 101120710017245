<template>
	<div>
		<Dialog :dialog="dialog" :dialogWidth="dialogWidth">
			<template v-slot:title>
				<v-layout class="align-items-center">
					<h3 class="mb-0">Manage Payment Terms</h3>
					<v-spacer></v-spacer>
					<v-btn
						tile
						depressed
						:disabled="page_loading"
						:loading="page_loading"
						class="custom-grey-border custom-bold-button white--text"
						color="blue darken-4 white--text"
						v-on:click="push_row()"
					>
						<v-icon left>mdi-plus</v-icon>
						Payment Terms
					</v-btn>
				</v-layout>
			</template>
			<template v-slot:body>
				<div>
					<v-form ref="payment-terms-form" v-model="form_valid" lazy-validation>
						<table width="100%" style="table-layout: fixed">
							<tr v-for="(row, index) in db_list" :key="index">
								<td>
									<TextInput
										hide-details
										:disabled="page_loading"
										placeholder="Enter Payment Terms"
										:loading="page_loading"
										:id="`payment-terms-${index}`"
										v-model="row.value"
										:class="{ required: !row.value, 'mb-3 has-delete-outer': true }"
										:rules="[vrules.required(row.value, 'Payment Terms')]"
									></TextInput>
								</td>
								<td width="100">
									<TextInput
										hide-details
										:disabled="page_loading"
										placeholder="Enter days"
										:loading="page_loading"
										:id="`payment-terms-${index}`"
										v-model="row.day"
										:class="{ required: !row.day, 'mb-3 has-delete-outer': true }"
										:rules="[vrules.required(row.day, 'Days')]"
									></TextInput>
								</td>
								<td width="30">
									<v-icon @click="remove_row(index, row)" color="red" class="ml-2">mdi-delete</v-icon>
								</td>
							</tr>
							<tr v-if="false">
								<td>
									<v-btn
										tile
										depressed
										:disabled="page_loading"
										:loading="page_loading"
										class="custom-grey-border custom-bold-button white--text"
										color="blue darken-4 white--text"
										v-on:click="push_row()"
									>
										<v-icon left>mdi-plus</v-icon>
										Payment Terms
									</v-btn>
								</td>
							</tr>
						</table>
					</v-form>
				</div>
			</template>
			<template v-slot:action>
				<v-btn
					tile
					depressed
					:disabled="page_loading"
					class="mx-2 custom-grey-border custom-bold-button"
					v-on:click="$emit('close', true)"
				>
					Close
				</v-btn>
				<v-btn
					tile
					depressed
					:disabled="page_loading"
					:loading="page_loading"
					class="mx-2 custom-grey-border custom-bold-button white--text"
					color="blue darken-4 white--text"
					v-on:click="submit()"
				>
					Save
				</v-btn>
			</template>
		</Dialog>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import { toSafeInteger /* cloneDeep */ } from "lodash";
import Dialog from "@/view/components/Dialog";
import TextInput from "@/view/components/TextInput";
import ApiService from "@/core/services/api.service";
import { SET_ERROR } from "@/core/services/store/common.module";

export default {
	data() {
		return {
			db_list: [],
			page_loading: false,
			form_valid: true,
		};
	},
	props: {
		list: {
			type: Array,
			default() {
				return [];
			},
		},
		dialog: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		Dialog,
		TextInput,
	},
	methods: {
		init() {
			//this.db_list = cloneDeep(this.list);
			this.db_list = this.localDB("payment_terms", []);
			if (this.db_list.length < 1) {
				this.push_row();
			}
		},
		remove_row(index, row) {
			if (row.id) {
				this.db_list.splice(index, 1);
			}
			this.db_list.splice(index, 1);

			if (this.db_list.length < 1) {
				this.push_row();
			}
		},
		push_row() {
			this.db_list.push({
				text: null,
				value: null,
				day: null,
				id: null,
			});
			this.$nextTick(() => {
				const focus_element = `payment-terms-${this.db_list.length - 1}`;
				if (focus_element) {
					document.getElementById(focus_element).focus();
				}
			});
		},
		submit() {
			const formErrors = this.validateForm(this.$refs["payment-terms-form"]);

			if (formErrors.length) {
				this.$store.commit(SET_ERROR, this.errors.concat(formErrors));
				return false;
			}

			if (!this.$refs["payment-terms-form"].validate()) {
				return false;
			}

			this.page_loading = true;

			ApiService.setHeader();
			ApiService.patch(`setting/payment-mode`, { list: this.db_list })
				.then(() => {
					this.$emit("close", true);
					this.$emit("success", true);
				})
				.catch((error) => {
					this.logError(error);
				})
				.finally(() => {
					this.page_loading = false;
				});
		},
	},
	mounted() {
		this.init();
	},
	computed: {
		...mapGetters(["errors", "localDB"]),
		dialogWidth() {
			if (document.body.clientWidth > 992) {
				return 640;
			}
			return toSafeInteger((document.body.clientWidth / 100) * 50);
		},
	},
};
</script>
